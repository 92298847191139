export const CAMPAIGN_PRODUCT_FIRST_FOLD_CONFIG = {
    crm: {
        title: "<span style=\"font-weight: 200; line-height: 110%; font-size: 72px;\">A CRM you'd actually<br>love to use</span>",
        subTitle: "<span style=\"font-weight: 300; line-height: 160%;\">Move deals faster with the most intuitive CRM – powered by AI and code-free automations.</span> <br> <br>",
        defaultSelectedTag: "CRM",
        topicImage: "/Generator_featured images/Homepage - 2024/personalisations-test/monday-for-CRM.png",
        buttonBelowText: "<div style=\"font-weight: 400; margin-top: 13px; font-size: 13px; line-height: 110%; color: ##676879;\">Start your free trial&nbsp;&nbsp;✦&nbsp;&nbsp;No credit card needed</div>"
    },
    dev: {
        title: "<span style=\"font-weight: 200; line-height: 110%; font-size: 72px;\">Develop with precision<br>and speed</span>",
        subTitle: "<span style=\"font-weight: 300; line-height: 160%;\">Plan roadmaps, manage sprints, and release products fast - on one flexible platform</span> <br> <br>",
        defaultSelectedTag: "Software",
        topicImage: "/Generator_featured images/Homepage - 2024/personalisations-test/monday-for-dev.png.png",
        buttonBelowText: "<div style=\"font-weight: 400; margin-top: 13px; font-size: 13px; line-height: 110%; color: ##676879;\">Start your free trial&nbsp;&nbsp;✦&nbsp;&nbsp;No credit card needed</div>"
    },
    service: {
        title: "<span style=\"font-weight: 200; line-height: 110%; font-size: 72px;\">AI, at your service</span>",
        subTitle: "<span style=\"font-weight: 300; line-height: 160%;\">Deliver exceptional service faster with an AI-powered platform your team will love.</span> <br> <br>",
        defaultSelectedTag: "IT",
        topicImage: "/Generator_featured images/Homepage - 2024/personalisations-test/monday-for-IT.png",
        buttonBelowText: "<div style=\"font-weight: 400; margin-top: 13px; font-size: 13px; line-height: 110%; color: ##676879;\">Start your free trial&nbsp;&nbsp;✦&nbsp;&nbsp;No credit card needed</div>"
    }
};
